<template>
	<v-container id="error-view" class="fill-height text-center" tag="section">
		<v-row justify="center" align="center" class="fill-height">
			<v-col cols="12" sm="8" md="8" lg="6" xl="4">
				<v-card elevation="0" class="pa-5 bg-fade">
					<v-card-title class="text-h4">
						<v-img alt="Rewind Logo" class="shrink mr-2" contain src="../../assets/logo_small.png" transition="scale-transition" width="40"/>
						rewind
					</v-card-title>
					<v-card-text>
						<v-form ref="form" lazy-validation>
							<v-text-field v-model="email" :rules="emailRules" label="Email" required></v-text-field>
							<v-card-actions color="transparent">
								<v-btn text rounded :to="{name:'Login'}">back</v-btn>
								<v-spacer></v-spacer>
								<v-btn @click="reset" rounded color="accent" :loading="loading">reset</v-btn>
							</v-card-actions>
						</v-form>
					</v-card-text>
					<v-card-text v-if="showResetMessage">
						<v-alert>an email has been sent to your email address with password reset instructions.</v-alert>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import {get} from "vuex-pathify";
import {toast} from '@/utils/helpers'

export default {
	name: 'ForgotPassword',
	metaInfo: {
		title: 'Forgot password',
	},
	computed: {
		user: get('user/user'),
	},
	data: () => ({
		loading: false,
		email: '',
		emailRules: [
			v => !!v || 'E-mail is required',
			v => /.+@.+/.test(v) || 'E-mail must be valid',
		],
		showResetMessage: false
	}),
	methods: {
		reset() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				this.$api.reset_password.create({email: this.email})
					.then(response => {
						this.showResetMessage = true;
					})
					.catch(error => {
						toast.add(error.message, 'error')
						console.log(error.response.status);
					})
				.finally(() => this.loading = false)
			}
		}
	}
}
</script>